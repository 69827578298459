"use client";

import rightArrow from "@/shared/images/right-arrow.webp";
import leftArrow from "@/shared/images/left-arrow.webp";
import Image from "next/image";
import "./GalleryModal.css";
import { IGalleryModal, ICarouselItem } from "./interfaces";
import { pinchZoom } from "@/shared/lib/pinchZoom";
import { useCarousel } from "@/shared/hooks/useCarousel";

export const GalleryModal: React.FC<IGalleryModal> = ({ content }) => {
  const makeNewGallery = (
    gallery: { attributes: { url: string } }[],
  ): ICarouselItem[] => {
    return gallery.map((item) => ({ imgUrl: item.attributes.url }));
  };

  const getImg = (i: { attributes: { url: string } }) => {
    return `https://api.blwall.com${i.attributes.url}`;
  };

  const newGallery = makeNewGallery(content.gallery);
  const config = {
    scrollStep: 1,
    threshold: 0.85,
    isVisible: true,
    initialIndex: content.index,
    inlinePosition: "center" as ScrollLogicalPosition,
  };

  const CarouselMain = useCarousel({ content: newGallery, config });
  const CarouselPreview = useCarousel({ content: newGallery, config });

  return (
    <div className="galleryModal">
      <div
        className="galleryModal__wrapper-outer"
        {...CarouselMain.swipeHandlers}
      >
        <Image
          width={35}
          height={14}
          className="galleryModal__icon"
          onMouseDown={CarouselPreview.handleScrollLeft}
          onMouseUp={CarouselMain.handleScrollLeft}
          onClick={(e) => e.preventDefault()}
          src={leftArrow}
          alt="left arrow"
        />
        <div
          className="galleryModal__wrapper-inner"
          ref={CarouselMain.rootRef}
          onKeyDown={CarouselMain.handleKeyPress}
          onWheel={CarouselMain.handleWheel}
        >
          {content.gallery.map((item, index) => (
            <div
              className="galleryModal__imgContainer"
              key={item.id}
              ref={(node) => {
                if (node) {
                  CarouselMain.itemsRef.current.set(index, node);
                } else {
                  CarouselMain.itemsRef.current.delete(index);
                }
              }}
            >
              <Image
                width={1200}
                height={1200}
                className="galleryModal__img"
                src={getImg(item)}
                key={index}
                alt="gallery item"
                onLoad={(img) => pinchZoom(img)}
              />
            </div>
          ))}
        </div>
        <Image
          width={35}
          height={14}
          className="galleryModal__icon"
          src={rightArrow}
          onMouseDown={CarouselPreview.handleScrollRight}
          onMouseUp={CarouselMain.handleScrollRight}
          onClick={(e) => e.preventDefault()}
          alt="right arrow"
        />
      </div>

      <div
        className={`galleryModal__preview`}
        onKeyDown={CarouselPreview.handleKeyPress}
        ref={CarouselPreview.rootRef}
        tabIndex={0}
        onWheel={CarouselPreview.handleWheel}
      >
        {content.gallery.map((item, index) => (
          <Image
            width={600}
            height={600}
            key={index}
            className={"galleryModal__preview-image"}
            src={`https://api.blwall.com` + item.attributes.url}
            alt="priview image"
            loading="lazy"
            ref={(node) => {
              if (node) {
                CarouselPreview.itemsRef.current.set(index, node);
              } else {
                CarouselPreview.itemsRef.current.delete(index);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

"use client";

import { IFeedbackItem } from "./interfaces";
import Image from "next/image";
import "./Reviews.css";
import { useReviewsCarousel } from "./useReviewsCarousel";
import Link from "next/link";
import leftArrow from "@/shared/images/arrow_l_mini.webp";
import rightArrow from "@/shared/images/arrow_r_mini.webp";

const ReviewsClient: React.FC<{ reviews: IFeedbackItem[] }> = ({ reviews }) => {
  const content = [...reviews];

  const {
    rootRef,
    itemsRef,
    currentIndex,
    swipeHandlers,
    handleKeyPress,
    handleScrollLeft,
    handleScrollRight,
    handleWheel,
  } = useReviewsCarousel(content);

  const blurhashImg =
    "data:image/bmp;base64,Qk32BAAAAAAAADYAAAAoAAAACAAAAAgAAAABABgAAAAAAMAAAAATCwAAEwsAAAAAAAAAAAAAAAAAAAAADRIjGSxCIDdTIDRSFx85AQAAAAAAAAAIEx00IDRRJj5hJDtfGSVGAAAAAAAAAAAeGCVCJz1fLEZuKUJsHCxSAAAHAAAAAAAkHCtLLENoMkx4LUd0HjFaAAAZAAAAAAAiHi1NMEVrNk97MUl4ITNdAAAcAAAAAAAXHixKMUVrOE97NEp3IjRcAAAXAAAAAAABHSpGMkNoOU55NUp1IzRZAAAOAAAAAAAAHSpEMkJnOk14Nkl0IzRYAAAJ";

  return (
    <section className="reviews" {...swipeHandlers}>
      <div className="reviews__wrapper">
        <div
          className="reviews__inner"
          ref={rootRef}
          onKeyDown={handleKeyPress}
          onWheel={handleWheel}
        >
          {reviews.map((review, index) => (
            <div
              key={index}
              className={`reviews__card ${
                currentIndex === index ? "reviews__card_active" : ""
              }`}
              ref={(node) => {
                if (node) {
                  itemsRef.current.set(index, node);
                } else {
                  itemsRef.current.delete(index);
                }
              }}
            >
              <div
                className="reviews__blurhash"
                style={{ backgroundImage: `url(${blurhashImg})` }}
              />

              <div className="reviews__header">
                <Link href={review.link}>
                  <span className="reviews__src">
                    {review.src.toUpperCase()}
                  </span>
                </Link>
                <span className="reviews__date">
                  {new Date(review.date).toLocaleDateString()}
                </span>
              </div>
              <p className="reviews__text">{review.text}</p>
              <div className="reviews__footer">
                <Image
                  src={`https://api.blwall.com${review.avatar.data.attributes.url}`}
                  alt={review.title}
                  width={40}
                  height={40}
                  className="reviews__avatar"
                />
                <div className="reviews__info">
                  <h4 className="reviews__name">{review.title}</h4>
                  {/* <p className="reviews__project">{review.projectDescription}</p> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="reviews__controls">
        <Image
          height={14}
          width={7.8}
          src={leftArrow}
          onClick={handleScrollLeft}
          className={`reviews__arrow ${
            currentIndex === 0 ? "reviews__arrow_inactive" : ""
          }`}
          alt="left arrow"
        />
        <div className="reviews__progress">
          <span className="reviews__progress-info">
            {currentIndex + 1}/{content.length}
          </span>
          <div className="reviews__progress-bar">
            <div
              className="reviews__progress-thumb"
              style={{
                width: `${((currentIndex + 1) / content.length) * 100}%`,
              }}
            ></div>
          </div>
        </div>
        <Image
          height={14}
          width={7.8}
          src={rightArrow}
          onClick={handleScrollRight}
          className={`reviews__arrow ${
            currentIndex === content.length - 1 ? "reviews__arrow_inactive" : ""
          }`}
          alt="right arrow"
        />
      </div>
    </section>
  );
};

export default ReviewsClient;

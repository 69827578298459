  "use client";

  import Image from "next/image";
  import { PageTitle } from "@/widgets/PageTitle/PageTitle";
  import "./Hero.css";
  import { IHeroClient } from "../types";
  import { Preloader } from "../../../shared/ui/Preloader/Preloader";
  import { useEffect, useRef } from "react";
  import { BlurhashCanvas } from "react-blurhash";

  const HeroClient: React.FC<IHeroClient> = ({ content, image }) => {
    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
      const header = document.querySelector(".header");
      if (header) {
        header.classList.add("header_visible");
      }
    }, []);

    const handleAnimationEnd = () => {
      if (imageRef.current) {
        imageRef.current.classList.remove("hidden");
      }
    };
    return (
      <section className={"hero"}>
        <Preloader />
        <picture className={"hero__bg"}>
          <div className="blurhash-wrapper" onAnimationEnd={handleAnimationEnd}>
            <BlurhashCanvas
              hash={"LOHo2k%LR*D$~pt7R*IUM_WBfj-;"}
              width={1920}
              height={1080}
              punch={1}
              className="blurhash-placeholder"
            />
          </div>

          <Image
            className={`hero__bgImg hidden`}
            src={image}
            alt="Hero"
            width={1920}
            height={1080}
            ref={imageRef}
            quality={100}
            loading="eager"
            priority
          />
        </picture>
        <PageTitle
          title={content.title}
          description={content.description}
          position="fixed"
        />
        <div className={"hero__overlay"}></div>
      </section>
    );
  };

  export default HeroClient;
